import handleMobileMenu from "./../_mobileMenu";

export default {

    init() {
        handleMobileMenu();
        const companies = document.querySelectorAll('.company')
        companies.forEach(company => {
            company.addEventListener('mouseenter', () => {
                company.classList.add('hovering')
            })
            company.addEventListener('mouseleave', () => {
                company.classList.remove('hovering')
            })
            company.addEventListener('click', () => {
                if (window.location.pathname == "/") {
                    document.location.href = company.classList.contains('company-one')
                        ? '/peer-comps/'
                        : company.classList.contains('company-two')
                            ? '/banker-valuation/'
                            : company.classList.contains('company-three')
                                ? '/finval/'
                                : '';
                }
            })
        })


        const blogs = document.querySelectorAll('.slanted-post');
        blogs.forEach(blog => {
            blog.addEventListener('mouseenter', () => {
                blog.classList.add('hovering')
            })
            blog.addEventListener('mouseleave', () => {
                blog.classList.remove('hovering')
            })
        })

    },

    finalize() {



    },

}